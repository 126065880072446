import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import encuestaReducer from '../reducers/encuestaReducer';

const store = createStore(
    encuestaReducer,
    applyMiddleware(thunk),
);

export default store;
