import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

const Home = React.lazy(() => import("./Home"));
const loading = () => <div>Loading...</div>;

const Reporte = React.lazy(() => import("./Reporte"));
const ReportePromedios = React.lazy(() => import("./ReportePromedios"));
class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/"
              name="Index"
              render={(props) => <Home {...props} />}
            />
            <Route
              exact
              path="/reportes"
              name="Index"
              render={(props) => <Reporte {...props} />}
            />
            <Route
              exact
              path="/promedios"
              name="Reporte Promedios"
              render={(props) => <ReportePromedios {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
