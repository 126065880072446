const defaultState = {
    encuesta: null,
    respuestas:[],
    load: false,
    socio: '',
    fin: false,
    listaEncuestas: null,
    reporte:null,
    login: false, //si se cambia volver a false
};

export default (state = defaultState, action) => {
    switch (action.type){
        case 'GET_ENCUESTA':
            return{
                ...state,
                encuesta: action.payload,
                load:true
            }
        case 'GET_ENCUESTAS':
            return{
                ...state,
                listaEncuestas: action.payload,
            }
        case 'SET_RESPUESTA':
            return{
                ...state,
                respuestas: state.respuestas.concat(action.payload)
            }
        case 'SET_REPORTE':
            return{
                ...state,
                reporte: action.payload
            }
        case 'IDENTIFICAR':
            return{
                ...state,
                socio: action.payload
            }
        case 'FIN_ENCUESTA':
            return{
                ...state,
                fin: true
            }
        case 'LOGIN_OK':
            return{
                ...state,
                login: true
            }
        default:
                return state;                  
    }   
}

